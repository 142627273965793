<template>
  <div
    class="overlay"
    @click="cancel"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'ReflectOverlay',
  slots: Object as SlotsType<{
    default: {}
  }>,
  emits: ['cancel'],
  methods: {
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  @include full-viewport-height();
  position: absolute;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}
</style>
